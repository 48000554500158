<template>
  <div class="progress-step" :class="progressStepCssClass">
    <div class="progress-step__title">{{ title }}</div>
    <div class="progress-step__subtitle">{{ subtitle }}</div>
    <div class="progress-step__percent">
      <ProgressBar :value="validatePercent" />
    </div>
  </div>
</template>

<script>
import ProgressBar from '@components/v2/minigames/ProgressBar.vue';

export default {
  name: 'ProgressStep',
  components: {
    ProgressBar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    percent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isStepFinish() {
      return this.percent >= 100;
    },
    progressStepCssClass() {
      return {
        'progress-step--finished': this.isStepFinish,
      };
    },
    validatePercent() {
      return this.percent < 100 ? this.percent : 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-step {
  width: 100%;
  text-align: center;

  &:not(.progress-step--finished) {
    color: #f5f5f7;
  }

  &--finished {
    color: #979797;
  }
}

.progress-step__title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.progress-step__subtitle {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
}
</style>
