<template>
  <div class="tournament-contact">
    <div class="tournament-contact__icon">
      <Icon :name="icon" group="general" :size="18" />
    </div>
    <a class="tournament-contact__link" target="_blank" :href="link">
      {{ text }}
    </a>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentContact',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.tournament-contact {
  display: flex;
  align-items: center;
  gap: 8px;
}
.tournament-contact__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  background: #161516;
}
.tournament-contact__link {
  font-size: 16px;
}
</style>
