<template>
  <header class="minigames-tournament-header">
    <div class="minigames-tournament-header__icon">
      <Icon :name="gameId" group="minigames" :size="96" />
    </div>
    <h1 class="minigames-tournament-header__title">
      {{ title }}
    </h1>
    <div class="minigames-tournament-header__progress">
      <ProgressStep
        :title="$t('minigames.startTournament')"
        :subtitle="startTime"
        :percent="100"
      />
      <ProgressStep
        :title="$t('minigames.finishTournament')"
        :subtitle="finishTime"
        :percent="progressPercent"
      />
    </div>
    <div class="minigames-tournament-header__properties">
      <MinigamesTournamentLabel
        :title="$t('minigames.startTournament')"
        :value="startTime"
        is-mobile
      />
      <MinigamesTournamentLabel
        :title="$t('minigames.finishTournament')"
        :value="finishTime"
        is-mobile
      />
      <MinigamesTournamentLabel title="Игра">
        <MinigameLabel :icon="gameId" :title="game.title" />
      </MinigamesTournamentLabel>
      <MinigamesTournamentLabel
        title="Участники"
        :value="participantAmount"
      />
      <MinigamesTournamentLabel
        title="Организатор"
        value="VK Play"
        is-accent
      />
    </div>
  </header>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import ProgressStep from '@components/v2/minigames/ProgressStep.vue';
import MinigameLabel from '@components/v2/minigames/MinigameLabel.vue';
import MinigamesTournamentLabel from '@components/v2/minigames/MinigamesTournamentLabel.vue';
import { getDateString } from '@utils/time.js';

export default {
  name: 'MinigamesTournamentHeader',
  components: {
    Icon,
    ProgressStep,
    MinigamesTournamentLabel,
    MinigameLabel,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    game: {
      type: Object,
      default: null,
    },
    tournament: {
      type: Object,
      default: null,
    },
  },
  computed: {
    participantAmount() {
      return this.tournament.playersCount;
    },
    progressPercent() {
      const durationSeconds =
        this.tournament.finishedAt - this.tournament.startedAt;
      const restSeconds =
        this.tournament.finishedAt - Math.trunc(Date.now() / 1000);
      const passedSeconds = durationSeconds - restSeconds;

      return Math.floor((passedSeconds * 100) / durationSeconds);
    },
    startTime() {
      return getDateString(this.tournament.startedAt, 'ru');
    },
    finishTime() {
      return getDateString(this.tournament.finishedAt, 'ru');
    },
    gameId() {
      return this.game.vkPlayGameId?.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.minigames-tournament-header {
  @include min-tablet() {
    padding-top: 96px;
  }
}

.minigames-tournament-header__icon {
  text-align: center;
  margin-bottom: 20px;
}

.minigames-tournament-header__title {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 30px;

  @include min-tablet() {
    margin-bottom: 96px;
  }
}

.minigames-tournament-header__progress {
  display: none;

  @include min-tablet() {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
}

.minigames-tournament-header__properties {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin: 0 -12px;
  padding: 16px;
  background: rgba(245, 245, 247, 0.12);

  @include min-tablet() {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 64px;
    margin: 0;
    padding: 0;
    background: none;
  }
}
</style>
