<template>
  <div class="progress-bar">
    <div
      class="progress-bar__appearance"
      :style="getAppearanceStyle"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getAppearanceStyle() {
      const percent =
        this.value > 0 && this.value <= 100 ? this.value : 0;
      return `width: ${percent}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.2);
}

.progress-bar__appearance {
  height: inherit;
  border-radius: inherit;
  background: #16a7ff;
}
</style>
