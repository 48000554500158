<template>
  <main-layout>
    <div v-if="tournamentData" class="minigames-tournament-page">
      <div class="minigames-tournament-page__header">
        <MinigamesTournamentHeader
          :title="mainTitle"
          :game="game"
          :tournament="tournamentData"
        />
      </div>
      <div class="minigames-tournament-page__tabs">
        <TabList
          :tabs="tabs"
          :start-tab-index="currentTabIndex"
          @change-tab="handleChangeTabList"
        />
      </div>
      <div class="minigames-tournament-page__main">
        <component
          :is="currentComponent.name"
          v-bind="{ ...currentComponent.props }"
          @show-more="fetchMoreRatingsData"
        />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MinigamesTournamentHeader from '@components/v2/minigames/MinigamesTournamentHeader.vue';
import TabList from '@components/v2/TabList.vue';
import TabContentMain from '@components/v2/minigames/TabContentMain.vue';
import TabContentRating from '@components/v2/minigames/TabContentRating.vue';
import TabContentContacts from '@components/v2/minigames/TabContentContacts.vue';

export default {
  name: 'MinigamesTournamentPage',
  components: {
    MinigamesTournamentHeader,
    TabContentMain,
    TabContentRating,
    TabContentContacts,
    TabList,
  },
  data() {
    return {
      tournamentData: null,
      isLoadingMoreRatings: false,
      ratingsData: [],
      ratingsPagination: {
        currentPage: 1,
        lastPage: 1,
      },
      tabs: [
        {
          title: 'Обзор',
          type: 'Main',
        },
        {
          title: 'Турнирная таблица',
          type: 'Rating',
        },
        {
          title: 'Контакты',
          type: 'Contacts',
        },
      ],
      currentTabIndex: 0,
    };
  },
  computed: {
    tournamentId() {
      return this.$route.params.id;
    },
    tournamentStatus() {
      return this.tournamentData.status;
    },
    mainTitle() {
      return this.tournamentData.title;
    },
    game() {
      return this.tournamentData.game;
    },
    gameId() {
      return this.game.vkPlayGameId.toLowerCase();
    },
    currentTabType() {
      return this.tabs[this.currentTabIndex].type;
    },
    currentComponent() {
      const components = {
        Main: {
          name: 'TabContentMain',
          props: {
            path:
              this.tournamentStatus === 'active'
                ? this.minigamePagePath
                : '',
            html: this.aboutTournamentHTML,
          },
        },
        Rating: {
          name: 'TabContentRating',
          props: {
            ratings: this.ratingsData,
          },
        },
        Contacts: {
          name: 'TabContentContacts',
          props: {},
        },
      };

      return components[this.currentTabType];
    },
    minigamePagePath() {
      return `/minigames/tournaments/${this.tournamentId}/playground`;
    },
    aboutTournamentHTML() {
      return this.tournamentData.description;
    },

    isLastRatingPage() {
      return (
        this.ratingsPagination.currentPage >=
        this.ratingsPagination.lastPage
      );
    },
  },
  async created() {
    Promise.all([
      this.fetchTournamentData(),
      this.fetchRatingsData(),
    ]);
  },
  methods: {
    async fetchTournamentData() {
      try {
        this.tournamentData = (
          await api.get(
            `v2/mini-games/tournaments/${this.tournamentId}`,
          )
        ).data;
      } catch (error) {
        this.$router.push({
          name: 'not-found',
        });
      }
    },
    async fetchRatingsData() {
      const response = await api.get(
        `v2/mini-games/rating/tournaments/${this.tournamentId}`,
        {
          params: {
            page: this.ratingsPagination.currentPage,
          },
        },
      );
      this.ratingsPagination.lastPage = response.pages.last;
      this.ratingsData = response.data.ratings;
    },
    async fetchMoreRatingsData() {
      if (this.isLoadingMoreRatings || this.isLastRatingPage) {
        return;
      }
      this.isLoadingMoreRatings = true;
      this.ratingsPagination.currentPage += 1;
      const response = await api.get(
        `v2/mini-games/rating/tournaments/${this.tournamentId}`,
        {
          params: {
            page: this.ratingsPagination.currentPage,
          },
        },
      );
      this.ratingsPagination.lastPage = response.pages.last;
      this.ratingsData.push(...response.data.ratings);
      this.isLoadingMoreRatings = false;
    },

    handleChangeTabList({ index }) {
      this.currentTabIndex = index;
    },
  },
};
</script>

<style scoped>
.minigames-tournament-page {
  max-width: 1224px;
  width: 100%;
  padding: 40px 12px;
  margin: 0 auto;
}

.minigames-tournament-page__header {
  margin-bottom: 40px;
}

.minigames-tournament-page__tabs {
  margin-bottom: 20px;
}
</style>
