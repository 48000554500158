<template>
  <MinigamesContainer>
    <!-- по задаче хардкодим контакты организатора VKPlay -->
    <TournamentContact
      icon="globe"
      text="https://support.vkplay.ru/pvp"
      link="https://support.vkplay.ru/pvp"
    />
  </MinigamesContainer>
</template>

<script>
import MinigamesContainer from '@components/v2/minigames/MinigamesContainer.vue';
import TournamentContact from '@components/v2/minigames/TournamentContact.vue';

export default {
  name: 'TabContentContacts',
  components: {
    MinigamesContainer,
    TournamentContact,
  },
};
</script>
