<template>
  <span
    class="minigames-tournament-label"
    :class="{ 'minigames-tournament-label--mobile': isMobile }"
  >
    <span class="minigames-tournament-label__title">
      {{ title }}
    </span>
    <span
      class="minigames-tournament-label__value"
      :class="{
        'minigames-tournament-label__value--accent': isAccent,
      }"
    >
      {{ value }}
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  name: 'MinigamesTournamentLabel',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isAccent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.minigames-tournament-label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  line-height: 125%;

  @include min-tablet() {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    letter-spacing: 0.3px;
  }
}

.minigames-tournament-label--mobile {
  @include min-tablet() {
    display: none;
  }
}

.minigames-tournament-label__title {
  color: #f5f5f7;
  opacity: 0.5;
}

.minigames-tournament-label__value {
  font-size: 16px;
  line-height: 125%;

  &--accent {
    color: #16a7ff;
  }
}
</style>
