<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="tab-content-main">
    <MinigamesContainer v-if="path">
      <div class="tab-content-main__button">
        <Button
          v-if="!hasMailAccount"
          :text="$t('account.authorize')"
          type="primary"
          size="big"
          long
          @click="login"
        />
        <Button
          v-else-if="!isLoggedIn"
          :text="$t('account.createProfile')"
          type="primary"
          size="big"
          long
          @click="showAdditionalRegistration"
        />
        <Button
          v-else
          tag="router-link"
          :to="path"
          :text="$t('global.doPlay')"
          type="secondary"
          size="big"
          long
        />
      </div>
    </MinigamesContainer>
    <MinigamesContainer>
      <span v-html="html" />
    </MinigamesContainer>
  </section>
</template>

<script>
import MinigamesContainer from '@components/v2/minigames/MinigamesContainer.vue';
import Button from '@components/v2/ui/Button.vue';
import { auth } from '@src/plugins/Auth';

export default {
  name: 'TabContentMain',
  components: {
    MinigamesContainer,
    Button,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    html: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'hasMailAccount']),
  },
  methods: {
    login() {
      const { backUrl } = this.$route.query;
      auth.login({ backUrl });
    },
    showAdditionalRegistration() {
      this.$store.commit(
        'profile/SET_IS_WITHOUT_GAME_ACCOUNT_LINK_MODAL',
        true,
      );
      this.$store.commit(
        'profile/SET_SHOW_ADDITIONAL_REGISTRATION',
        true,
      );
    },
  },
};
</script>

<style scoped>
.tab-content-main__button {
  max-width: 200px;
  margin: 0 auto;
}
</style>
