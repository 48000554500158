<template>
  <div class="minigames-container">
    <div class="minigames-container__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MinigamesContainer',
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.minigames-container {
  padding: 32px 16px;
  border-radius: 4px;
  background-color: #2d2d30;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.minigames-container__content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
</style>
