<template>
  <span class="minigame-label">
    <span class="minigame-label__icon">
      <Icon :name="icon" group="minigames" :size="18" />
    </span>
    <span class="minigame-label__title">{{ title }}</span>
  </span>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MinigameLabel',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.minigame-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 18px;
}

.minigame-label__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid rgba(245, 245, 247, 0.12);
  background: #161516;
}
</style>
