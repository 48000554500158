<template>
  <div ref="mainContent">
    <Table v-if="ratings.length">
      <template #thead>
        <TableRow>
          <TableData heading small>
            {{ $t('minigames.tableHeaderPlace') }}
          </TableData>
          <TableData heading text-align="left">
            {{ $t('minigames.tableHeaderParticipant') }}
          </TableData>
          <TableData heading small>
            {{ $t('minigames.tableHeaderRatings') }}
          </TableData>
        </TableRow>
      </template>
      <template #tbody>
        <TableRow
          v-for="(participant, idx) in ratings"
          :key="participant.player.id"
        >
          <TableData small>{{ idx + 1 }}</TableData>
          <TableData text-align="left">
            <img
              v-if="participant.player.avatarUrl"
              class="tab-content-rating__avatar"
              :src="participant.player.avatarUrl"
              alt="player avatar"
            />
            <span v-else class="tab-content-rating__avatar">
              <Icon name="user" :size="32" />
            </span>
            <span class="tab-content-rating__nickname">
              {{ participant.player.nick }}
            </span>
          </TableData>
          <TableData small>
            {{ participant.chipsCount }}
          </TableData>
        </TableRow>
      </template>
    </Table>
    <MinigamesContainer v-else>
      <h2 class="tab-content-rating__message">
        {{ $t('tournament.not_participants') }}
      </h2>
    </MinigamesContainer>
  </div>
</template>

<script>
import MinigamesContainer from '@components/v2/minigames/MinigamesContainer.vue';
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import Icon from '../utils/Icon.vue';

export default {
  name: 'TabContentRating',
  components: {
    MinigamesContainer,
    Table,
    TableRow,
    TableData,
    Icon,
  },
  props: {
    ratings: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleWindowScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  },
  methods: {
    handleWindowScroll() {
      const windowHeight = document.documentElement.clientHeight;
      const elementCoords =
        this.$refs.mainContent.getBoundingClientRect();
      const isBottomVisible =
        elementCoords.bottom < windowHeight &&
        elementCoords.bottom > 0;

      if (isBottomVisible) {
        this.$emit('show-more');
      }
    },
  },
};
</script>

<style scoped>
.tab-content-rating__message {
  text-align: center;
  font-size: 18px;
}

.tab-content-rating__wrapper {
  display: flex;
  gap: 8px;
}

.tab-content-rating__avatar {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #2d2d30;
  overflow: hidden;
  vertical-align: middle;
}

.tab-content-rating__nickname {
  margin-left: 8px;
}
</style>
